import React, { useState } from 'react';
import './login.css';
import arthWhite from '../../assets/img/arth_logo_white.svg';
import { useNavigate } from 'react-router-dom';
import loginService from '../../service/login.service';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState('');

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const handlePwdInput = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    const login_data = {
      email: email,
      encrypted_pswd: password,
      grant_type: 'password',
      app_slug: 'LMS',
    };
    try {
      const response = await loginService().authenticateUser(login_data);      
      if (response.access_token) {
        localStorage.setItem('auth_token', response.access_token);
        localStorage.setItem('email', email);
        navigate('/dashboard');
      } else {
        setError(response.message || 'Login failed');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <div className="">
        <div className="card login-card">
          <div className="image-side">
            <div>
              <img className="logo" src={arthWhite} alt="ARTH Logo" />
              <h2
                style={{ color: '#fff', fontSize: '1.4rem', fontWeight: '300' }}
              >
                ARTH - Powering Micro Businesses
              </h2>
            </div>
          </div>
          <div className="form-side">
            <div
              style={{
                marginBottom: '1.4rem',
                fontSize: '1rem',
                fontWeight: '300',
              }}
            >
              Login
            </div>
            <div className="form-field">
              <div className="login-password-text">Email</div>
              <input
                type="email"
                className="form-input-field"
                onChange={handleEmailInput}
                value={email}
                placeholder="Enter your email"
              />
            </div>
            <div className="form-field">
              <div className="login-password-text">Password</div>
              <input
                type="password"
                className="form-input-field"
                onChange={handlePwdInput}
                value={password}
                placeholder="Enter your password"
              />
            </div>
            {error && <div className="login-error">{error}</div>}
            <div className="login-button-position">
              <button className="login-button" onClick={handleLogin}>
                <span>Login</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
